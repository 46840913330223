import { ProviderType, UnifedType } from "./type"

//unified portal 所在的地址
export const unifiedUrls: UnifedType = [
  { url: "//api.bmwgroup.com.cn", env: "Prod" },
  { url: "//api-t.bmwgroup.com.cn", env: "Uat" },
  { url: "//api-i.bmwgroup.com.cn", env: "Int" },
  { url: "//localhost:3000", env: "Dev" }
]

export const getNetEnv = (domain: string) => unifiedUrls.filter((el) => domain.includes(el.url))


export const providers: ProviderType = new Map([
  ["Internet", [
    { net: "NSC", url: "https://apim.nsc.bmwgroup.com.cn", env: "Prod" },//先放外网的测试
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Uat" },
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Int" },
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Dev" },
    { net: "BBA", url: "https://api.bmwgroup.com.cn/403", env: "Prod" },//暂时bba外网全部改成403
    { net: "BBA", url: "https://api-t.bmwgroup.com.cn/403", env: "Uat" },
    { net: "BBA", url: "https://api-i.bmwgroup.com.cn/403", env: "Int" },
    { net: "BBA", url: "//localhost:3000/403", env: "Dev" },
  ]],
  ["Intranet", [
    { net: "NSC", url: "https://apim.nsc.bmwgroup.com.cn", env: "Prod" },//先放外网的测试
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Uat" },
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Int" },
    { net: "NSC", url: "https://apim.nsc-dev.bmwgroup.com.cn", env: "Dev" },
    { net: "BBA", url: "https://cnapi-backend.bmw-brilliance.cn/sso/v1/oauth/url?from=", env: "Prod" },
    { net: "BBA", url: "https://cnapi-backend-uat.bmw-brilliance.cn/sso/v1/oauth/url?from=", env: "Uat" },
    { net: "BBA", url: "https://cnapi-backend-int.bmw-brilliance.cn/sso/v1/oauth/url?from=", env: "Int" },
    { net: "BBA", url: "https://cnapi-backend-int.bmw-brilliance.cn/sso/v1/oauth/url?from=", env: "Dev" },
  ]]
])