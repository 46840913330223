import * as React from "react";
import { checkTargetUrl } from "./net";
import "../styles/login.scss";
import axios from "axios";

// else where in your component

export default function Login() {
  const identityList = [
    { id: "BBA", name: "BMW Brillance Automotive" },
    { id: "NSC", name: "BMW Group" },
  ];
  const identityInit = localStorage.getItem("identity") || "BBA";
  const [identity, setIdentity] = React.useState(identityInit);
  const [usection, setUsection] = React.useState("");

  const identityClick = (id: string) => {
    localStorage.setItem("identity", id);
    setIdentity(id);
  };

  React.useEffect(() => {
    getIp();
  }, []);

  const getIp = () => {
    const PPATH = '/apiplatform/unifiedportal/v1/request/section'
    const url = `${process.env.REACT_APP_BASE_URL}${PPATH}`;
    axios
      .get(url)
      .then((res) => {
        const {code,desc,section} = res.data;
        if(code  === 200){
          setUsection(section)
        }else{
          console.error(desc);
        }
      })
      .catch((err) => {
        console.error("parsing failed", err);
      });
  };

  const toLogin = () => {
    console.log(identity, usection);
    
    checkTargetUrl(identity, usection);
  };

  return (
    <div className="unified">
      <div className="login">
        <img className="logo" src="../images/bmw-logo.png" alt="bmw logo" />
        <img className="left" src="../images/left-bg.svg" alt="left" />
        <div className="box">
          <img
            className="api-logo"
            src="../images/logo-ys.svg"
            alt="api logo"
          />
          {/* <h2 className="api-info">API PORTAL</h2> */}
          <div className="provider">
            {/* <h5>Identity Provider</h5> */}
            {identityList.map((el) => (
              <button
                key={el.id}
                onClick={() => identityClick(el.id)}
                className={`pro-btn ${el.id === identity ? "active" : ""}`}
              >
                {el.name} User
              </button>
            ))}
          </div>
          <button
            className={`login-btn ${!usection ? "disabled" : ""}`}
            onClick={toLogin}
            disabled={!usection}
          >
            Login
          </button>
        </div>
        <img className="right" src="../images/right-bg.svg" alt="right" />
      </div>
    </div>
  );
}
