import "../styles/404.scss"

export default function noFound() {
  return (
    <div className="special">
      <img className="icon" src="../images/404.svg" alt="" />
      <div className="word">
        <h3>Page Not Found</h3>
        <p>Sorry, the page not found.
          The link you followed <br /> broken, or the page has been removed.</p>
        <a href="/">Back Home</a>
      </div>
    </div>
  )
}