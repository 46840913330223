import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import Login from './pages/login';
import Deny from './pages/403';
import NotFound from './pages/404';
import './styles/reset.scss'

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/403" element={<Deny />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
