import { providers, getNetEnv } from "../utils";

export const checkTargetUrl = (identity: string, section: string) => {

  //登录地址相关属性属于内网还是外网
  const curWebUrl = location.href;
  const curNetWorks = getNetEnv(curWebUrl);
  console.log(curNetWorks);
  

  let network = section;
  let { env } = curNetWorks[0];
  //identity protal选择的NSC/BBA
  //network为当前的登录unfied protal是内网地址还是外网地址
  //如果network为Intranet,需判断当前网络的ip出口判断当前网络是BBA内网还是NSC内网


  if (section === "BBA" || section === "NSC") {
    network = "Intranet";
  }
  if (section === "BBA" && identity === "NSC") {
    network = "Internet";
  }
  
  handleProviders(network, identity, env);
};

const handleProviders = (
  network: string,
  identity: string,
  env?: string
) => {
  console.log(network, identity, env);
  if (env) {
    providers.forEach((value, key) => {
      if (network === key) {
        const targetPortal = value.filter(
          (el) => el.net === identity && el.env === env
        );
        if (targetPortal.length > 0) {
          location.href = targetPortal[0].url;
        }
      }
    });
  } else {
    console.error("log in url is vaild");
  }
};
